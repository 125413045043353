import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import env from './env';
import pkg from '../package.json';

import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: env.SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [
                'localhost',
                /^https:\/\/api\.shoppbud\.com\.br/,
            ],
        }),
        new Sentry.Integrations.TryCatch(),
        new Sentry.Integrations.GlobalHandlers({
            onerror: true,
            onunhandledrejection: true,
        }),
        new Sentry.Integrations.FunctionToString(),
    ],
    release: pkg.version,
    environment: env.ENV,
    enableTracing: true,
    tracesSampleRate: 1.0,
    autoSessionTracking: true,
    enabled: !import.meta.env.DEV,
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
    ],
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
reportWebVitals();
